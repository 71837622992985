import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { APP_CONFIG } from '../app.config';
import { Observable, throwError } from 'rxjs';
import { GenericCat } from '../models/genericcat';
import { Articulo } from '../models/articulo';
import { Orden } from '../models/orden';
import { Shop } from '../models/shop';
import { Tienda } from '../models/tienda';
import {catchError, map} from 'rxjs/operators'; 

@Injectable({
  providedIn: 'root'
})
export class CrearTiendaService {


  msgId: number;
  nuevaTienda= {} as Tienda;

  // eslint-disable-next-line @typescript-eslint/member-ordering
  constructor(private http: HttpClient) {  }

  errorMsg(error: HttpErrorResponse){
    let errorMessage = '';
   if(error.error instanceof ErrorEvent){
     errorMessage = error.error.message;
   }else{
     errorMessage = `Error Code: ${error.status}\n Message : ${error.message}`;

   }
     return throwError(errorMessage);
 }




  crearTienda(data: any): Observable<any> {
    const nombre= `${data.nombre} ${data.apellido}`;
    const newShop = {
      chatId : data.chatId,
      name : nombre,
      email : data.correo,
      shopName : data.nombreTienda
    };
    let url = 'https://tienda.crambly.com.mx/api/cuentas/register';
    return this.http.post(url, newShop)
    .pipe(
      catchError(this.errorMsg)
    );
  }

}
