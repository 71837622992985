/* eslint-disable no-var */
import { Component, OnInit} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { element } from 'protractor';
import { APP_CONFIG } from '../app.config';
import { Articulo } from '../models/articulo';
import { GenericCat } from '../models/genericcat';
import { Orden } from '../models/orden';
import { OrdenDetalle } from '../models/ordendetalle';
import { Shop } from '../models/shop';
import { ShopService } from '../services/shop.service';
import { MenuController } from '@ionic/angular';
import { FiltroPage } from '../filtro/filtro.page';
import { Tienda } from '../models/tienda';
import { CrearTiendaService } from '../services/crearTienda.service';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.page.html',
  styleUrls: ['./inicio.scss']
})




export class InicioPage implements OnInit{
  term: string;
  lista: Articulo[]=[];
  tiendasOriginal: Shop[]=[];
  tiendas: Shop[]=[];
  categorias: GenericCat[];
  tienda= {} as Shop;
  filesPath = APP_CONFIG.filesPath;
  filesProducts = APP_CONFIG.filesProducts;
  assets = APP_CONFIG.assets;
  idShop = "";
  orderMode = true;
  finished = false;
  orden: Orden = { total : 0, oDetalle: [] } as Orden;
  tgApp: any;
  search: boolean = false;
  categoriasf= [];
  catdato=[];
  nuevatienda = {} as Tienda;

  // eslint-disable-next-line max-len
  constructor(
    public service: ShopService,
    private route: ActivatedRoute,
    private menuCtrl: MenuController,
    private createTservice: CrearTiendaService) { }

  ngOnInit() {
    this.tgApp = window["tgApp"];
    this.route.paramMap.subscribe( params => {
      this.idShop = params.get("shopid");
      this.orden.organizacionID = parseInt(params.get("shopid"));
      this.orden.chatId = parseInt(params.get("chatid"));
      this.orden.username = params.get("username");
      this.orden.first_name = params.get("first_name");
      this.orden.last_name = params.get("last_name");
      this.orden.msgId = parseInt(params.get("msgid"));
      this.createTservice.msgId = parseInt(params.get("shopid"));
      this.createTservice.nuevaTienda.chatId = parseInt(params.get("chatid"));
      this.createTservice.nuevaTienda.username = params.get("username");
      this.createTservice.nuevaTienda.nombre = params.get("first_name");
      this.createTservice.nuevaTienda.apellido = params.get("last_name");
      this.createTservice.nuevaTienda.msgId = parseInt(params.get("msgid"));
      if (this.idShop!=null) {
        this.service.getArticulos(this.idShop).subscribe( res => {
          this.lista = res;
          this.lista.forEach(element => { element.cantidad=0 });
        });
      }
    });
  }

  openMenu() {
    this.menuCtrl.toggle();
  }

  filtroPalabra(event){
    const txtBuscar = event.detail.value;
    if(txtBuscar!=""){
      // eslint-disable-next-line max-len
      this.service.shopsF = this.service.shopsFst.filter(x=>x.nombre.toUpperCase().indexOf(txtBuscar.toUpperCase())>-1 || x.categoriaTienda.toUpperCase().indexOf(txtBuscar.toUpperCase())>-1 || x.descripcion.toUpperCase().indexOf(txtBuscar.toUpperCase())>-1);
    }else{
      this.service.shopsF = this.service.shopsFst;
    }
  }


  filtrar(item: GenericCat, status: boolean){
    item.additional4 = status;
    this.categoriasf = this.categorias.filter(x=>x.additional4 == true);

    this.catdato = this.categoriasf.map(x=>x.description);
    this.tiendas=[];

    this.tiendasOriginal.forEach(item=>{
      this.catdato.forEach(element => {
        if (item.categoriaTienda == element) this.tiendas.push(item);
      });
    });
  }

}
