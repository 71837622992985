import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { APP_CONFIG } from '../app.config';
import { Observable } from 'rxjs';
import { GenericCat } from '../models/genericcat';
import { Articulo } from '../models/articulo';
import { Orden } from '../models/orden';
import { Shop } from '../models/shop';

@Injectable({
  providedIn: 'root'
})
export class ShopService {

  categoriasF: GenericCat[] = [];

  shops: Shop[]=[];

  shopsFst: Shop[]=[];

  shopsF: Shop[]=[];

  private URL = APP_CONFIG.urlBaseServices + '/api/shop';

  constructor(private http: HttpClient) {  }

  getArticulos(id: string): Observable<Articulo[]> {
    return this.http.get<Articulo[]>(this.URL + '/items/' + id);
  }

  getTiendas(): Observable<Shop[]> {
    return this.http.get<Shop[]>(this.URL + '/tiendas/');
  }

  getCategoriasT(): Observable<any> {
    return this.http.get<any>(this.URL + '/categoriat/');
  }

  getItem(id: string): Observable<Articulo> {
    return this.http.get<Articulo>(this.URL + '/item/'+id);
  }

  sendOrder(orden: Orden): Observable<Orden> {
    return this.http.post<Orden>(this.URL, orden);
  }

}
