/* eslint-disable no-var */
import { Component, OnInit, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { element } from 'protractor';
import { APP_CONFIG } from '../app.config';
import { Articulo } from '../models/articulo';
import { GenericCat } from '../models/genericcat';
import { Orden } from '../models/orden';
import { OrdenDetalle } from '../models/ordendetalle';
import { Shop } from '../models/shop';
import { ShopService } from '../services/shop.service';
import { MenuController } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
import { FiltroPage } from '../filtro/filtro.page';
import { Location } from '@angular/common';
import { AlertController } from '@ionic/angular';
import { Tienda } from '../models/tienda';
import {FormGroup,  FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { ToastController } from '@ionic/angular';
import { CrearTiendaService } from '../services/crearTienda.service';

@Component({
  selector: 'app-crearTienda',
  templateUrl: './crearTienda.page.html',
  styleUrls: ['./CrearTienda.scss']
})




export class CrearTiendaPage implements OnInit {
  lista: Articulo[]=[];
  tiendasOriginal: Shop[]=[];
  tiendas: Shop[]=[];
  categorias: GenericCat[];
  filesPath = APP_CONFIG.filesPath;
  filesProducts = APP_CONFIG.filesProducts;
  assets = APP_CONFIG.assets;
  nuevaTienda= {} as Tienda;
  chatId: number;
  nombre: string;
  apellido: string;
  correo: string;
  nombreTienda: string;
  submitted = false;
  newTienda!: FormGroup;

  // eslint-disable-next-line max-len
  constructor(private service: ShopService,
     private route: ActivatedRoute,
     private toastCtrl: ToastController,
     public fb: FormBuilder,
     private ngZone: NgZone,
     private location: Location,
     private alertCtrl: AlertController,
     private router: Router,
     private createTservice: CrearTiendaService) {
  this.mainForm();
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params =>{
      this.chatId = parseInt(params.get('chatid'));
    });
    this.service.getCategoriasT().subscribe(res => {
      this.categorias = res.data as GenericCat[];
      this.categorias.forEach(element =>{
        element.additional4 = true;
      });
    });

    this.service.getTiendas().subscribe(res => {
      this.service.shops = res;
      this.service.shopsF = this.service.shops;
      this.service.shops.forEach(element =>{
        element.descripcion = element.descripcion==null ? "": element.descripcion;
      });
    });
  }

  mainForm(){
    this.newTienda = this.fb.group({
      nombre : ['', [Validators.required]],
      apellido : ['', [Validators.required]],
      correo : ['', [Validators.required, Validators.pattern('[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}')]],
      nombreTienda : ['', [Validators.required]],
    });
  }


  back(): void {
    this.location.back();
  }

  async createToast(message: string, color: string) {
    const toast = await this.toastCtrl.create({
      message: message,
      position: 'bottom',
      duration: 5000,
      color: color
    });
    toast.present();
  }


  async alert() {
    const alert = await this.alertCtrl.create({
      header: '¿Estás seguro crear una tienda?',
      message:  `
      ¿Los datos son correctos?<br>
      <b>Nombre</b>: ${this.nombre} ${this.apellido} <br>
      <b>Correo</b>: ${this.correo} <br>
      <b>Nombre de Tienda</b>: ${this.nombreTienda} <br>`,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          id: 'cancel-button',
        }, {
          text: 'Agregar',
          role: 'add',
          id: 'confirm-button',
        }
      ]
    });
    await alert.present();
    const { role } = await alert.onDidDismiss();
    return role;
  }

  get myForm(){
    return this.newTienda.controls;
  }

  async crearTienda(){
    const agregar = await this.alert();
    if(agregar == 'add'){
      const datos = {
        chatId : this.chatId,
        nombre : this.nombre,
        apellido : this.apellido,
        correo : this.correo,
        nombreTienda : this.nombreTienda
      };
      this.createTservice.crearTienda(datos).subscribe(res => {
        const message = `Te hemos enviado un correo electrónico a <b>${this.correo}</b> con los pasos a seguir.`;
        this.createToast(message, 'success');
        // eslint-disable-next-line max-len
        const url = `/inicio/1/${this.chatId}/${this.createTservice.nuevaTienda.username}/${this.createTservice.nuevaTienda.nombre}/${this.createTservice.nuevaTienda.apellido}/${this.createTservice.msgId}/resto`;
        this.ngZone.run(() => this.router.navigateByUrl(url));
      }, error =>{
        const message = `<b>Ha ocurrido un error.</b><br> Intenténtalo de nuevo más tarde o verifica tus datos`;
        this.createToast(message, 'danger');
        // eslint-disable-next-line max-len
      });
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    }else{
      const message = `La tienda no se ha creado`;
      this.createToast(message, 'danger');
    }
  }

  submit(){
    this.submitted = true;
    if(this.newTienda.status != "INVALID" ){
      this.nombre = this.newTienda.controls.nombre.value;
      this.apellido = this.newTienda.controls.apellido.value;
      this.correo = this.newTienda.controls.correo.value;
      this.nombreTienda = this.newTienda.controls.nombreTienda.value;
      this.crearTienda();
    }
  }



}
