/* eslint-disable no-var */
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { element } from 'protractor';
import { APP_CONFIG } from '../app.config';
import { Articulo } from '../models/articulo';
import { GenericCat } from '../models/genericcat';
import { Orden } from '../models/orden';
import { OrdenDetalle } from '../models/ordendetalle';
import { Shop } from '../models/shop';
import { ShopService } from '../services/shop.service';
import { MenuController } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
import { FiltroPage } from '../filtro/filtro.page';
import { Tienda } from '../models/tienda';
import { CrearTiendaService } from '../services/crearTienda.service';

@Component({
  selector: 'menuapp',
  templateUrl: './menu.page.html',
  styleUrls: ['./menu.scss']
})




export class MenuPage implements OnInit {
  tiendas: Shop[]=[];
  categorias: GenericCat[];
  tienda= {} as Shop;
  filesPath = APP_CONFIG.filesPath;
  filesProducts = APP_CONFIG.filesProducts;
  assets = APP_CONFIG.assets;
  categoriasf= [];
  catdato=[];
  nuevatienda= {} as Tienda;
  nombre: string;
  apellido: string;
  chatId: number;


  // eslint-disable-next-line max-len
  constructor(private service: ShopService, private route: ActivatedRoute, private modalCtrl: ModalController, public createTservice: CrearTiendaService, private menuCtrl: MenuController) {
  }

  ngOnInit() {
    this.service.getCategoriasT().subscribe(res => {
      this.categorias = res.data as GenericCat[];
      this.categorias.forEach(element =>{
        element.additional4 = true;
      });
    });

    this.service.getTiendas().subscribe(res => {
      this.service.shops = res;
      this.service.shopsFst = this.service.shops;
      this.service.shopsF = this.service.shopsFst;
      this.service.shops.forEach(element =>{
        element.descripcion = element.descripcion==null ? "": element.descripcion;
      });
    });
  }

  closeMenu(){
    this.menuCtrl.close();
  }

  async mostrarModal() {
    const modal = await this.modalCtrl.create({
      component: FiltroPage,
      componentProps: {
        categorias: this.categorias,
      }
    });
    await modal.present();
    this.closeMenu();
  }

}
