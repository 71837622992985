/* eslint-disable no-var */
import { Component, Input, OnInit, EventEmitter, Output} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { element } from 'protractor';
import { APP_CONFIG } from '../app.config';
import { Articulo } from '../models/articulo';
import { GenericCat } from '../models/genericcat';
import { Orden } from '../models/orden';
import { OrdenDetalle } from '../models/ordendetalle';
import { Shop } from '../models/shop';
import { ShopService } from '../services/shop.service';
import { MenuController } from '@ionic/angular';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-filtro',
  templateUrl: './filtro.page.html',
  styleUrls: ['./filtro.scss']
})




export class FiltroPage implements OnInit {
  @Input() categorias: GenericCat[];
  //@Output() catFiltroEvent = new EventEmitter<any>();
  term: string;
  lista: Articulo[]=[];
  tiendasOriginal: Shop[]=[];
  tiendas: Shop[]=[];
  //categorias: GenericCat[];
  tienda= {} as Shop;
  filesPath = APP_CONFIG.filesPath;
  filesProducts = APP_CONFIG.filesProducts;
  assets = APP_CONFIG.assets;
  idShop = "";
  orderMode = true;
  finished = false;
  orden: Orden = { total : 0, oDetalle: [] } as Orden;
  tgApp: any;
  search: boolean = false;
  categoriasf= [];
  catdato=[];
  status: boolean;

  constructor(private service: ShopService, private route: ActivatedRoute, public modalCtrl: ModalController) {
  }

  ngOnInit() {
    this.categoriasf = this.categorias;
  }

  filtroCat(event: any, item: GenericCat){
    this.status = event.detail.checked;
    item.additional4 = this.status;
    this.categoriasf = this.categorias.filter(x=>x.additional4 == true);
    this.catdato = this.categoriasf.map(x=>x.description);
    this.service.categoriasF = this.categoriasf;
    this.tiendas = [];
    this.service.shopsF = [];
    this.service.shops.forEach(item=>{
      this.catdato.forEach(element => {
        if (item.categoriaTienda == element) this.service.shopsF.push(item);
      });
    });
    this.service.shopsFst = this.service.shopsF;
  }
}
