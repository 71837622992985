export const APP_CONFIG = {
  urlBaseServices: 'https://tienda.crambly.com.mx',
  //urlBaseServices: 'https://localhost:44308',
  //urlBaseServices: 'https://svss.com.mx/',


  filesPath: 'https://www.files.cramblyapp.com',
  filesProducts: 'https://www.files.cramblyapp.com/files/ecomerce',
  assets: 'https://admin.cramblyapp.com/assets',
  copyright: '2022 © Crambly Shop',
};
